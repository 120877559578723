import { Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

import useAbility from '@okapi/domains/Auth/hooks/useAbility';

import styles from './styles.module.less';

interface Props {
  className?: string;
  mode?: 'vertical' | 'horizontal' | 'inline';
  onMenuItemClick?: () => void;
}

const MainNavigation: FC<Props> = (props) => {
  const { canAccess } = useAbility();
  const { className, mode = 'horizontal', onMenuItemClick = () => {} } = props;
  const { pathname } = useLocation();
  const selectedKey = pathname.split('/')[1];

  const items: ItemType[] = [
    ...(canAccess('dashboard')
      ? [
          {
            key: 'dashboard',
            label: (
              <div className={styles.menuItem}>
                <span className={`icon-dashboard ${styles.icon}`} />
                <Link to="/dashboard">Dashboard</Link>
              </div>
            )
          }
        ]
      : []),
    {
      key: 'books',
      label: (
        <div className={styles.menuItem}>
          <span className={`icon-book ${styles.icon}`} />
          <Link to="/books">Books</Link>
        </div>
      )
    },
    {
      key: 'resources',
      label: (
        <div className={styles.menuItem}>
          <span className={`icon-resources ${styles.icon}`} />
          <Link to="/resources">Resources</Link>
        </div>
      )
    },
    ...(canAccess('districts')
      ? [
          {
            key: 'districts',
            label: (
              <div className={styles.menuItem}>
                <span className={`icon-district ${styles.icon}`} />
                <Link to="/districts">Districts</Link>
              </div>
            )
          }
        ]
      : []),
    ...(canAccess('schools')
      ? [
          {
            key: 'schools',
            label: (
              <div className={styles.menuItem}>
                <span className={`icon-school ${styles.icon}`} />
                <Link to="/schools">Schools</Link>
              </div>
            )
          }
        ]
      : []),
    ...(canAccess('teachers')
      ? [
          {
            key: 'teachers',
            label: (
              <div className={styles.menuItem}>
                <span className={`icon-users ${styles.icon}`} />
                <Link to="/teachers">Teachers</Link>
              </div>
            )
          }
        ]
      : [])
  ];

  return <Menu className={className} items={items} mode={mode} onClick={onMenuItemClick} selectedKeys={[selectedKey]} />;
};

export default MainNavigation;
