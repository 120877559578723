import { Checkbox, Form } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import cx from 'classnames';
import React, { FC } from 'react';

import { Option } from '@okapi/types';

import styles from './styles.module.less';

interface Props {
  className?: string;
  name?: string;
  onChange?: (values: CheckboxValueType[]) => void;
  options: Option[];
}

const CheckboxGroup: FC<Props> = ({ className, name, onChange, options }) => {
  const classNames = cx(styles.group, className);

  return (
    <Form.Item name={name} noStyle={true}>
      <Checkbox.Group className={classNames} name={name} onChange={onChange} options={options} />
    </Form.Item>
  );
};

export default CheckboxGroup;
