import { Option } from '@okapi/types';
import { Optional } from '@okapi/utils/typescript';

import { ResourceFiltersQueryResult } from '../graphql/resourceFilters.generated';
import { ResourceFilters } from './types';

const mapFilterOptions = (options: Optional<{ id: number; key: string; value: string }[]>): Option[] => {
  if (!options) return [];

  return options.map(({ id, value }) => ({ label: value, value: id }));
};

const filterSeries = ({ value }: Option) => {
  const validSeriesIds = [3, 4];

  return validSeriesIds.includes(value as number);
};

const filterStages = ({ value }: Option) => {
  const validStagesIds = [7, 8, 9];

  return validStagesIds.includes(value as number);
};

export const limitFilterOptions = ({ series, stages }: ResourceFilters): ResourceFilters => ({
  series: series.filter(filterSeries),
  stages: stages.filter(filterStages)
});

export const prepareFilters = (data: Optional<ResourceFiltersQueryResult>): ResourceFilters => {
  const filters = {
    series: mapFilterOptions(data?.filters?.series),
    stages: mapFilterOptions(data?.filters?.stages)
  };

  return filters;
};
