import { Form, Select } from 'antd';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import React, { FC, ReactNode } from 'react';

import { Option } from '@okapi/types';

import styles from './styles.module.less';

interface Props {
  dropdownWidth?: boolean | number;
  name: string;
  options: Option[];
  placeholder?: string;
}

const MultiSelect: FC<Props> = ({ dropdownWidth = true, name, options, placeholder = 'Select options' }) => {
  const { getFieldValue } = Form.useFormInstance();

  const value = getFieldValue(name);
  const hasValue: boolean = !isEmpty(value);

  const renderTagPlaceholder = (selectedValues: { label?: ReactNode }[]) => `${selectedValues.length} selected`;

  return (
    <Form.Item name={name} noStyle={true}>
      <Select
        allowClear={{ clearIcon: <span className={`icon-close ${styles.closeIcon}`} /> }}
        className={cx({ [styles.selected]: hasValue, [styles.notSelected]: !hasValue }, 'styled-select')}
        data-testid="multi-select"
        maxTagCount={0}
        maxTagPlaceholder={renderTagPlaceholder}
        mode="multiple"
        options={options}
        placeholder={placeholder}
        popupClassName="styled-select"
        popupMatchSelectWidth={dropdownWidth}
        showSearch={false}
        style={{ width: '100%' }}
        suffixIcon={<span className="icon-chevron-down" />}
      />
    </Form.Item>
  );
};

export default MultiSelect;
