import { Card, Typography } from 'antd';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import banner from './images/resources.png';
import { resources } from './ResourceList.config';
import styles from './styles.module.less';
import { Resource } from './types';

const BookList: FC = () => {
  const renderResource = ({ title, imageUrl, description, url }: Resource, index: number) => (
    <Link key={index} to={url}>
      <Card
        bodyStyle={{ flex: 1, padding: 0, display: 'flex', flexDirection: 'column' }}
        className={styles.card}
        cover={<img alt={title} src={imageUrl} />}
        hoverable={true}
        size="small"
      >
        <div className={styles.resource}>
          <Typography.Title className={styles.title} level={3}>
            {title}
          </Typography.Title>
          <div className={styles.description}>{description}</div>
        </div>
      </Card>
    </Link>
  );

  return (
    <>
      <Helmet>
        <title>Resources</title>
      </Helmet>
      <div className={styles.header}>
        <div className={styles.slogan}>
          <div>
            <h1 className={styles.headline}>Welcome to Your Online Resource Archive!</h1>
            <div className={styles.tagline}>
              Download valuable instructional tools for use with Okapi’s flagship programs. Choose from among these indispensable
              resources...
            </div>
          </div>
        </div>
        <div className={styles.banner}>
          <img alt="Resources" className={styles.bannerImage} src={banner} />
        </div>
      </div>
      <div className={styles.resources}>{resources.map(renderResource)}</div>
    </>
  );
};

export default BookList;
