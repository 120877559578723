import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type AnalyticsQueryVariables = GraphQLTypes.Exact<{
  page: GraphQLTypes.GetPage;
}>;

export type AnalyticsQueryResult = { __typename?: 'Query' } & {
  books: { __typename?: 'PaginatedBook' } & Pick<GraphQLTypes.PaginatedBook, 'totalCount'>;
  districts: { __typename?: 'PaginatedDistrict' } & Pick<GraphQLTypes.PaginatedDistrict, 'totalCount'>;
  schools: { __typename?: 'PaginatedSchool' } & Pick<GraphQLTypes.PaginatedSchool, 'totalCount'>;
  users: { __typename?: 'PaginatedUser' } & Pick<GraphQLTypes.PaginatedUser, 'totalCount'>;
  analytics: { __typename?: 'Analytics' } & Pick<GraphQLTypes.Analytics, 'activeUsers'> & {
      dailyUsers: Array<{ __typename?: 'AnalyticsData' } & Pick<GraphQLTypes.AnalyticsData, 'name' | 'value' | 'previousValue'>>;
      topBooks: Array<{ __typename?: 'AnalyticsData' } & Pick<GraphQLTypes.AnalyticsData, 'name' | 'value'>>;
      topSearches: Array<{ __typename?: 'AnalyticsData' } & Pick<GraphQLTypes.AnalyticsData, 'name' | 'value'>>;
      topBookFilters: Array<{ __typename?: 'AnalyticsData' } & Pick<GraphQLTypes.AnalyticsData, 'name' | 'value'>>;
      loginMethods: Array<{ __typename?: 'AnalyticsData' } & Pick<GraphQLTypes.AnalyticsData, 'name' | 'value'>>;
      districts: Array<{ __typename?: 'AnalyticsData' } & Pick<GraphQLTypes.AnalyticsData, 'name' | 'value'>>;
    };
};

export const AnalyticsDocument = /* #__PURE__ */ gql`
  query analytics($page: GetPage!) {
    books(page: $page, filters: { bookType: [1, 2, 3, 4, 5, 6] }) {
      totalCount
    }
    districts {
      totalCount
    }
    schools {
      totalCount
    }
    users(filters: { status: [ACTIVE], roles: [TEACHER] }) {
      totalCount
    }
    analytics {
      activeUsers
      dailyUsers {
        name
        value
        previousValue
      }
      topBooks {
        name
        value
      }
      topSearches {
        name
        value
      }
      topBookFilters {
        name
        value
      }
      loginMethods {
        name
        value
      }
      districts {
        name
        value
      }
    }
  }
`;

/**
 * __useAnalyticsQuery__
 *
 * To run a query within a React component, call `useAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<AnalyticsQueryResult, AnalyticsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<AnalyticsQueryResult, AnalyticsQueryVariables>(AnalyticsDocument, options);
}
export function useAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsQueryResult, AnalyticsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<AnalyticsQueryResult, AnalyticsQueryVariables>(AnalyticsDocument, options);
}
export function useAnalyticsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AnalyticsQueryResult, AnalyticsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<AnalyticsQueryResult, AnalyticsQueryVariables>(AnalyticsDocument, options);
}
export type AnalyticsQueryHookResult = ReturnType<typeof useAnalyticsQuery>;
export type AnalyticsLazyQueryHookResult = ReturnType<typeof useAnalyticsLazyQuery>;
export type AnalyticsSuspenseQueryHookResult = ReturnType<typeof useAnalyticsSuspenseQuery>;
