import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type BooksQueryVariables = GraphQLTypes.Exact<{
  filters: GraphQLTypes.BooksFilters;
  page: GraphQLTypes.GetPage;
}>;

export type BooksQueryResult = { __typename?: 'Query' } & {
  books: { __typename?: 'PaginatedBook' } & Pick<GraphQLTypes.PaginatedBook, 'totalCount'> & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          { __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'asset' | 'id' | 'title' | 'includedInDemo'> & {
              lessonPlan?: GraphQLTypes.Maybe<{ __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'id'>>;
            }
        >
      >;
    };
};

export const BooksDocument = /* #__PURE__ */ gql`
  query books($filters: BooksFilters!, $page: GetPage!) {
    books(filters: $filters, page: $page) {
      nodes {
        asset
        id
        title
        lessonPlan {
          id
        }
        includedInDemo
      }
      totalCount
    }
  }
`;

/**
 * __useBooksQuery__
 *
 * To run a query within a React component, call `useBooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useBooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBooksQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useBooksQuery(baseOptions: Apollo.QueryHookOptions<BooksQueryResult, BooksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<BooksQueryResult, BooksQueryVariables>(BooksDocument, options);
}
export function useBooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BooksQueryResult, BooksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<BooksQueryResult, BooksQueryVariables>(BooksDocument, options);
}
export function useBooksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BooksQueryResult, BooksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<BooksQueryResult, BooksQueryVariables>(BooksDocument, options);
}
export type BooksQueryHookResult = ReturnType<typeof useBooksQuery>;
export type BooksLazyQueryHookResult = ReturnType<typeof useBooksLazyQuery>;
export type BooksSuspenseQueryHookResult = ReturnType<typeof useBooksSuspenseQuery>;
